class Application {
    constructor(selector) {
        this.selector = selector;
        this.modal = document.querySelector('.js-application-modal');

        this.selector.addEventListener('click', (e) => {
            e.preventDefault();

            if (this.modal !== null) {
                let input = this.modal.querySelector('[name="title"]');
                input.value = this.selector.getAttribute('data-value');

                document.querySelector('body').classList.add('overlay');
                document.querySelector('body').style.overflowY = 'hidden';
                document.querySelector('html').style.overflowY = 'hidden';

                this.modal.classList.add('modal--shown');

                let close = this.modal.querySelector('[data-element="close"]');
                if (close !== null) {
                    close.addEventListener('click', (e) => {
                        e.preventDefault();

                        this.modal.classList.remove('modal--shown');
                        document.querySelector('body').classList.remove('overlay');
                        document.querySelector('body').style.overflowY = 'initial';
                        document.querySelector('html').style.overflowY = 'initial';

                        input.value = '';
                    });
                }
            }
        });
    }
}

export default Application;