class Sidebar {
    constructor(container) {
        this.container = container;
        this.sidebar = container.querySelector('[data-sidebar]');
        this.sidebarContainer = container.querySelector('[data-sidebar-container]');
        this.header = document.querySelector('.header');
        this.containers = container.querySelector('[data-container]');

        // console.log(this.sidebar.offsetHeight);
        // console.log(this.containers.offsetHeight);

        this._stickSidebar = this._stickSidebar.bind(this);
        this.sidebar.style.width = (this.sidebar.offsetWidth - 60) + 'px';
        console.log((this.sidebar.offsetWidth - 60));

        if(this.containers.offsetHeight > this.sidebar.offsetHeight) {
            window.addEventListener('scroll', this._stickSidebar);
        }
    }

    _stickSidebar(){
        let distanceToTop = this.sidebarContainer.getBoundingClientRect().top;
        let headerHeight = this.header.offsetHeight + 10;

        if(distanceToTop > headerHeight){
            this.sidebar.classList.remove('job-offer-sidebar--fixed');
        } else if (distanceToTop > headerHeight - this.sidebarContainer.offsetHeight + this.sidebar.offsetHeight) {
            this.sidebar.classList.add('job-offer-sidebar--fixed');
            this.sidebar.style.top = headerHeight + 'px';
            this.sidebar.classList.remove('job-offer-sidebar--bottom');
        } else {
            this.sidebar.classList.add('job-offer-sidebar--bottom');
            this.sidebar.style.top = 'auto';
            // console.log('jest');
        }
    }
}

export default Sidebar;