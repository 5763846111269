import form from './form';
import Swiper from 'swiper';
import Sidebar from "./sidebar";
import Application from "./application";
import { Form as BaseForm } from '@netivo/base-scripts';

/**
 * Created by Michał on 11.05.2017.
 */
function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}
function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 300;
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }

    let header = document.querySelector('.js-header');
    let body = document.querySelector('body');
    if(window.scrollY > 30) {
        header.classList.add('header--scrolled');
        body.style.marginTop = 30 + 'px';
    } else {
        header.classList.remove('header--scrolled');
        body.style.marginTop = 0 + 'px';
    }
});

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
       event.preventDefault();
       toggleMenu();
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'hidden';
            document.querySelector('html').style.overflow = 'hidden';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
        }
    });
}

window.addEventListener('DOMContentLoaded', (event) => {

    File.prototype.convertToBase64 = function(callback){
        let reader = new FileReader();
        reader.onloadend = function (e) {
            callback(e.target.result, e.target.error);
        };
        reader.readAsDataURL(this);
    };

    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 300;
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }

    let formElement = document.querySelector('.js-form');
    if (formElement !== null) {
        form.init({
            action: '/wp-admin/admin-ajax.php',
            handler: formElement,
            data: element => {
                let data = {
                    first_name: '',
                    email: '',
                    subject: '',
                    message: '',
                    action: 'nt_send_form'
                };
                let first_name = element.querySelector('[name="first_name"]');
                let email = element.querySelector('[name="email"]');
                let subject = element.querySelector('[name="subject"]');
                let message = element.querySelector('[name="message"]');

                if (first_name !== null) data.first_name = first_name.value;
                if (email !== null) data.email = email.value;
                if (subject !== null) data.subject = subject.value;
                if (message !== null) data.message = message.value;
                return data;
            },
            beforeSubmit: (element, hashed) => {
                let loader = element.querySelector('.loader__background');
                loader.style.display = 'flex';
                loader.querySelector('.loader').style.display = 'block';
            },
            success: (element, response) => {
                if (response.status === 'success') {
                    let loader = element.querySelector('.loader__background');
                    let successMessage = loader.querySelector('.loader__success');
                    successMessage.innerHTML = 'Dziękujemy, wiadomość została wysłana.';
                    loader.querySelector('.loader').style.display = 'none';
                    element.querySelector('[name="first_name"]').value = '';
                    element.querySelector('[name="email"]').value = '';
                    element.querySelector('[name="subject"]').value = '';
                    element.querySelector('[name="message"]').value = '';
                    successMessage.style.display = 'block';
                    setTimeout(() => {
                        loader.style.display = 'none';
                        successMessage.style.display = 'none';
                        successMessage.innerHTML = '';
                    }, 8000);
                }
            },
            error: (element, response) => {
                console.log(response);
                let loader = element.querySelector('.loader__background');
                let errorMessage = loader.querySelector('.loader__error');
                errorMessage.innerHTML = 'Przepraszamy wystąpił problem. Odśwież stronę i spróbuj ponownie.';
                loader.querySelector('.loader').style.display = 'none';
                errorMessage.style.display = 'block';
                setTimeout(() => {
                    loader.style.display = 'none';
                    errorMessage.style.display = 'none';
                    errorMessage.innerHTML = '';
                }, 8000);
            }
        });
    }

    let company_application_form = document.querySelector('.js-form-company-application');
    if (company_application_form !== null) {
        form.init({
            action: '/wp-admin/admin-ajax.php',
            handler: company_application_form,
            data: element => {
                let data = {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    company_name: '',
                    type: [],
                    description: '',
                    description_trans: '',
                    url: '',
                    field: '',
                    action: 'nt_send_company_application_form'
                };
                let company_name = element.querySelector('[name="company_name"]');
                let first_name = element.querySelector('[name="first_name"]');
                let last_name = element.querySelector('[name="last_name"]');
                let email = element.querySelector('[name="email"]');
                let phone = element.querySelector('[name="phone"]');
                let type_array = [];
                element.querySelectorAll('[name="type"]').forEach((item) => {
                    if (item.checked) {
                        type_array.push(item.getAttribute('value'));
                    }
                });
                let description = element.querySelector('[name="description"]');
                let description_trans = element.querySelector('[name="description_trans"]');
                let url = element.querySelector('[name="url"]');
                let field_array = [];
                element.querySelectorAll('[name="field"]').forEach((item) => {
                    if (item.checked) {
                        field_array.push(item.getAttribute('value'));
                    }
                });

                if (company_name !== null) data.company_name = company_name.value;
                if (first_name !== null) data.first_name = first_name.value;
                if (last_name !== null) data.last_name = last_name.value;
                if (email !== null) data.email = email.value;
                if (phone !== null) data.phone = phone.value;
                if (type_array.length > 0) data.type = type_array;
                if (description !== null) data.description = description.value;
                if (description_trans !== null) data.description_trans = description_trans.value;
                if (url !== null) data.url = url.value;
                if (field_array.length > 0) data.field = field_array;
                return data;
            },
            beforeSubmit: (element, hashed) => {
                let loader = element.querySelector('.loader__background');
                loader.style.display = 'flex';
                loader.querySelector('.loader').style.display = 'block';
            },
            success: (element, response) => {
                if (response.status === 'success') {
                    let loader = element.querySelector('.loader__background');
                    let successMessage = loader.querySelector('.loader__success');
                    successMessage.innerHTML = 'Dziękujemy, wiadomość została wysłana.';
                    loader.querySelector('.loader').style.display = 'none';
                    element.querySelector('[name="company_name"]').value = '';
                    element.querySelector('[name="first_name"]').value = '';
                    element.querySelector('[name="last_name"]').value = '';
                    element.querySelector('[name="email"]').value = '';
                    element.querySelector('[name="phone"]').value = '';
                    element.querySelector('[name="url"]').value = '';
                    element.querySelector('[name="description"]').value = '';
                    element.querySelector('[name="description_trans"]').value = '';
                    element.querySelectorAll('[name="type"]').forEach((item) => {
                        if (item.checked) {
                            item.checked = false;
                        }
                    });
                    element.querySelectorAll('[name="field"]').forEach((item) => {
                        if (item.checked) {
                            item.checked = false;
                        }
                    });
                    successMessage.style.display = 'block';
                    setTimeout(() => {
                        loader.style.display = 'none';
                        successMessage.style.display = 'none';
                        successMessage.innerHTML = '';
                    }, 8000);
                }
            },
            error: (element, response) => {
                console.log(response);
                let loader = element.querySelector('.loader__background');
                let errorMessage = loader.querySelector('.loader__error');
                errorMessage.innerHTML = 'Przepraszamy wystąpił problem. Odśwież stronę i spróbuj ponownie.';
                loader.querySelector('.loader').style.display = 'none';
                errorMessage.style.display = 'block';
                setTimeout(() => {
                    loader.style.display = 'none';
                    errorMessage.style.display = 'none';
                    errorMessage.innerHTML = '';
                }, 8000);
            }
        });
    }

    let team = document.querySelector('.js-team');
    if (team != null) {
        let pers = team.querySelectorAll('.js-person');
        if (pers != null) {
            pers.forEach(function(item) {
                let parent = item.parentNode;
                let button = item;
                item.addEventListener('click', function(e) {
                    e.preventDefault();
                    if (item.classList.contains('open')) {
                        item.classList.remove('open');
                        parent.classList.remove('open');
                        button.innerHTML = 'Przeczytaj >';
                    } else {
                        item.classList.add('open');
                        parent.classList.add('open');
                        button.innerHTML = '< Zwiń';
                    }
                });
            });
        }
        let window_width = window.innerWidth;
        // console.log(window_width);
        let team_members = team.querySelectorAll('[data-element="person"]');
        if (team_members != null) {
             if (window_width <= 991) {
                // console.log('991');
                team_members.forEach(function (item) {
                    item.classList.remove('team-grid__list-item--right');
                });

                document.querySelectorAll('[data-element="person"]:nth-of-type(2n)').forEach(function (item) {
                    item.classList.add('team-grid__list-item--right');
                });
            } else if (window_width <= 1199) {
                 // console.log('1199');
                 team_members.forEach(function (item) {
                     item.classList.remove('team-grid__list-item--right');
                 });

                 document.querySelectorAll('[data-element="person"]:nth-of-type(3n+3)').forEach(function (item) {
                     item.classList.add('team-grid__list-item--right');
                 });
            }
        }
    }
    let swiper_partners = new Swiper('.swiper-partners', {
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        autoplay: true,
        breakpoints: {
            321: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            500: {
                slidesPerView: 3,
            },
            501: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 3,
            },
            992: {
                slidesPerView: 4,
            },
            1024: {
                slidesPerView: 4,
            },
        }
    });

    let swiper_sponsors = new Swiper('.swiper-sponsors', {
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        autoplay: true,
        breakpoints: {
            321: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            500: {
                slidesPerView: 3,
            },
            501: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 3,
            },
            992: {
                slidesPerView: 4,
            },
            1024: {
                slidesPerView: 4,
            },
        }
    });

    let load_more_event = document.querySelector('.js-load-more-events');
    if (load_more_event != null) {
        load_more_event.addEventListener('click', function(e) {
            e.preventDefault();

            let per_page = load_more_event.getAttribute('data-per-page');
            let page = load_more_event.getAttribute('data-page');

            console.log('load more');

            return fetch('/wp-json/wp/v2/event?_embed&per_page=' + per_page + '&page=' + page, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                redirect: 'follow'
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(posts => {
                        let list = load_more_event.parentNode.querySelector('[data-element="container"]');

                        posts.forEach(item => {
                            let d = new Date(item.meta._nt_event_date);
                            let final_date = d.getDate() + '.' + d.getMonth() + '.' + d.getFullYear();

                            let element_html = '';
                            element_html += '<div class="events-list__item" data-post-id="' + item.id + '">';
                            element_html += '<div class="events-list__item-data">';
                            element_html += '<span class="date">' + final_date + '</span>';
                            if (typeof item.meta._nt_event_location != 'undefined') element_html += '<div class="separator">|</div>';
                            if (typeof item.meta._nt_event_location != 'undefined') element_html += '<span class="location">' + item.meta._nt_event_location + '</span>';
                            if (typeof item.meta._nt_event_location != 'undefined' && typeof item.meta._nt_event_category != 'undefined') element_html += '<div class="separator">|</div>';
                            if (typeof item.meta._nt_event_category != 'undefined') element_html += '<span class="category">' + item.meta._nt_event_category + '</span>';
                            element_html += '</div>';
                            element_html += '<a href="' + item.link + '" class="events-list__item-image-container">';
                            if (typeof item._embedded['wp:featuredmedia']['0'].source_url != 'undefined') element_html += '<img alt="' + item.title.rendered + '" src="' + item._embedded['wp:featuredmedia']['0'].source_url + '" />';
                            element_html += '</a>';
                            element_html += '<div class="events-list__item-organiser">Wydarzenie organizowane przez ' + item.meta._nt_event_organizer + '</div>';
                            element_html += '<h3 class="events-list__item-title"><a href="' + item.link + '">' + item.title.rendered + '</a></h3>';
                            element_html += '<a href="' + item.link + '" class="button button--right">Zobacz więcej ></a>';
                            element_html += '</div>';

                            list.innerHTML += element_html;
                        });
                        let result = parseInt(page) + parseInt("1");
                        load_more_event.setAttribute('data-page', result);
                    });
                }
            });
        });
    }

    let load_more_publications = document.querySelector('.js-load-more-publications');
    if (load_more_publications != null) {
        load_more_publications.addEventListener('click', function(e) {
            e.preventDefault();

            let per_page = load_more_publications.getAttribute('data-per-page');
            let page = load_more_publications.getAttribute('data-page');

            return fetch('/wp-json/wp/v2/event?_embed&per_page=' + per_page + '&page=' + page, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                redirect: 'follow'
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(posts => {
                        let list = load_more_publications.parentNode.querySelector('[data-element="container"]');

                        posts.forEach(item => {

                            let element_html = '';
                            element_html += '<div class="publication-list__item" data-post-id="' + item.id + '">';
                            element_html += item.title.rendered;
                            element_html += '</div>';

                            list.innerHTML += element_html;
                        });
                        let result = parseInt(page) + parseInt("1");
                        load_more_publications.setAttribute('data-page', result);
                    });
                }
            });
        });
    }

    let modal = document.querySelector('.js-modal');
    if (modal !== null) {
        document.querySelector('body').classList.add('overlay');
        document.querySelector('body').style.overflowY = 'hidden';
        document.querySelector('html').style.overflowY = 'hidden';
        let close = modal.querySelector('[data-element="close"]');
        close.addEventListener('click', e => {
            e.preventDefault();

            modal.classList.remove('modal--shown');
            document.querySelector('body').classList.remove('overlay');
            document.querySelector('body').style.overflowY = 'initial';
            document.querySelector('html').style.overflowY = 'initial';
        });
    }

    let sidebarSticky = document.querySelectorAll('.js-sidebar');
    if(sidebarSticky.length > 0){
        sidebarSticky.forEach(container => {
            new Sidebar(container);
        });
    }

    let application_buttons = document.querySelectorAll('.js-applicate');
    if (application_buttons !== null) {
        application_buttons.forEach(button => {
           new Application(button);
        });
    }

    let application_form = document.querySelector('.js-form-application');
    if (application_form !== null) {
        new BaseForm(application_form,{
            action: '/wp-json/edtech/v1/new_application',
            data: element => {
                return new Promise((resolve, reject) => {
                    let data = {
                        first_last_name: '',
                        email: '',
                        phone: '',
                        message: '',
                        file: '',
                        title: ''
                    };
                    let first_last_name = element.querySelector('[name="first_last_name"]');
                    let email = element.querySelector('[name="email"]');
                    let phone = element.querySelector('[name="phone"]');
                    let message = element.querySelector('[name="message"]');
                    let file = element.querySelector('[name="file"]');
                    let title = element.querySelector('[name="title"]');

                    if (first_last_name !== null) data.first_last_name = first_last_name.value;
                    if (email !== null) data.email = email.value;
                    if (phone !== null) data.phone = phone.value;
                    if (message !== null) data.message = message.value;
                    if (title !== null) data.title = title.value;
                    if (file !== null && file.value !== '') {
                        let f = element.querySelector('[name="file"]').files[0];
                        f.convertToBase64(base64 => {
                            data.file = encodeURIComponent(base64);
                            data.file_name = f.name;
                            resolve(data);
                        });
                    } else {
                        resolve(data);
                    }
                });
            },
            beforeSubmit: (element, hashed) => {
                let loader = element.querySelector('.loader__background');
                loader.style.display = 'flex';
                loader.querySelector('.loader').style.display = 'block';
                return true;
            },
            success: (element, response) => {
                if (response.status === 'success') {
                    let loader = element.querySelector('.loader__background');
                    let successMessage = loader.querySelector('.loader__success');
                    successMessage.innerHTML = 'Dziękujemy, otrzymaliśmy Twoje zgłoszenie.';
                    loader.querySelector('.loader').style.display = 'none';
                    element.querySelector('[name="first_last_name"]').value = '';
                    element.querySelector('[name="email"]').value = '';
                    element.querySelector('[name="phone"]').value = '';
                    element.querySelector('[name="message"]').value = '';
                    element.querySelector('[name="file"]').value = '';
                    successMessage.style.display = 'block';
                    setTimeout(() => {
                        loader.style.display = 'none';
                        successMessage.style.display = 'none';
                        successMessage.innerHTML = '';
                    }, 8000);
                }
            },
            error: (element, response) => {
                console.log(response);
                let loader = element.querySelector('.loader__background');
                let errorMessage = loader.querySelector('.loader__error');
                errorMessage.innerHTML = 'Przepraszamy wystąpił problem. Odśwież stronę i spróbuj ponownie.';
                loader.querySelector('.loader').style.display = 'none';
                errorMessage.style.display = 'block';
                setTimeout(() => {
                    loader.style.display = 'none';
                    errorMessage.style.display = 'none';
                    errorMessage.innerHTML = '';
                }, 8000);
            }
        });
    }

    let job_offer_application_form = document.querySelector('.js-form-job-offer-application');
    if (job_offer_application_form !== null) {
        new BaseForm(job_offer_application_form,{
            action: '/wp-admin/admin-ajax.php',
            data: element => {
                return new Promise((resolve, reject) => {
                    let data = {
                        company_name: '',
                        company_url: '',
                        company_file: '',
                        company_file_name: '',

                        offer_title: '',
                        offer_description: '',
                        offer_requirements: '',
                        offer_conditions: '',
                        offer_location: '',
                        offer_application_link: '',
                        offer_contact_email: '',
                        action: 'nt_send_job_offer_application_form'
                    };
                    let company_name = element.querySelector('[name="company_name"]');
                    let company_url = element.querySelector('[name="company_url"]');
                    let company_file = element.querySelector('[name="company_file"]');
                    let offer_title = element.querySelector('[name="title"]');
                    let offer_description = element.querySelector('[name="description"]');
                    let offer_requirements = element.querySelector('[name="requirements"]');
                    let offer_conditions = element.querySelector('[name="conditions"]');
                    let offer_location = element.querySelector('[name="location"]');
                    let offer_application_link = element.querySelector('[name="application_url"]');
                    let offer_contact_email = element.querySelector('[name="contact_email"]');

                    if (company_name !== null) data.company_name = company_name.value;
                    if (company_url !== null) data.company_url = company_url.value;
                    if (offer_title !== null) data.offer_title = offer_title.value;
                    if (offer_description !== null) data.offer_description = offer_description.value;
                    if (offer_requirements !== null) data.offer_requirements = offer_requirements.value;
                    if (offer_conditions !== null) data.offer_conditions = offer_conditions.value;
                    if (offer_location !== null) data.offer_location = offer_location.value;
                    if (offer_application_link !== null) data.offer_application_link = offer_application_link.value;
                    if (offer_contact_email !== null) data.offer_contact_email = offer_contact_email.value;
                    if (company_file !== null && company_file.value !== '') {
                        let f = element.querySelector('[name="company_file"]').files[0];
                        f.convertToBase64(base64 => {
                            data.company_file = encodeURIComponent(base64);
                            data.company_file_name = f.name;
                            resolve(data);
                        });
                    } else {
                        resolve(data);
                    }
                });
            },
            beforeSubmit: (element, hashed) => {
                let loader = element.querySelector('.loader__background');
                loader.style.display = 'flex';
                loader.querySelector('.loader').style.display = 'block';
                return true;
            },
            success: (element, response) => {
                if (response.status === 'success') {
                    let loader = element.querySelector('.loader__background');
                    let successMessage = loader.querySelector('.loader__success');
                    successMessage.innerHTML = 'Dziękujemy, otrzymaliśmy Twoje zgłoszenie.';
                    loader.querySelector('.loader').style.display = 'none';
                    element.querySelector('[name="company_name"]').value = '';
                    element.querySelector('[name="company_url"]').value = '';
                    element.querySelector('[name="company_file"]').value = '';
                    element.querySelector('[name="title"]').value = '';
                    element.querySelector('[name="description"]').value = '';
                    element.querySelector('[name="requirements"]').value = '';
                    element.querySelector('[name="conditions"]').value = '';
                    element.querySelector('[name="location"]').value = '';
                    element.querySelector('[name="application_url"]').value = '';
                    element.querySelector('[name="contact_email"]').value = '';
                    successMessage.style.display = 'block';
                    setTimeout(() => {
                        loader.style.display = 'none';
                        successMessage.style.display = 'none';
                        successMessage.innerHTML = '';
                    }, 8000);
                }
            },
            error: (element, response) => {
                console.log(response);
                let loader = element.querySelector('.loader__background');
                let errorMessage = loader.querySelector('.loader__error');
                errorMessage.innerHTML = 'Przepraszamy wystąpił problem. Odśwież stronę i spróbuj ponownie.';
                loader.querySelector('.loader').style.display = 'none';
                errorMessage.style.display = 'block';
                setTimeout(() => {
                    loader.style.display = 'none';
                    errorMessage.style.display = 'none';
                    errorMessage.innerHTML = '';
                }, 8000);
            }
        });
    }

    // let job_offer_application_form = document.querySelector('.js-form-job-offer-application');
    // if (job_offer_application_form !== null) {
    //     form.init({
    //         action: '/wp-admin/admin-ajax.php',
    //         handler: job_offer_application_form,
    //         data: element => {
    //             let data = {
    //                 company_name: '',
    //                 company_url: '',
    //                 company_file: '',
    //                 company_file_name: '',
    //
    //                 offer_title: '',
    //                 offer_description: '',
    //                 offer_requirements: '',
    //                 offer_conditions: '',
    //                 offer_location: '',
    //                 offer_application_link: '',
    //                 offer_contact_email: '',
    //                 action: 'nt_send_job_offer_application_form'
    //             };
    //             let company_name = element.querySelector('[name="company_name"]');
    //             let company_url = element.querySelector('[name="company_url"]');
    //             let company_file = element.querySelector('[name="company_file"]');
    //             let offer_title = element.querySelector('[name="title"]');
    //             let offer_description = element.querySelector('[name="description"]');
    //             let offer_requirements = element.querySelector('[name="requirements"]');
    //             let offer_conditions = element.querySelector('[name="conditions"]');
    //             let offer_location = element.querySelector('[name="location"]');
    //             let offer_application_link = element.querySelector('[name="application_url"]');
    //             let offer_contact_email = element.querySelector('[name="contact_email"]');
    //
    //             if (company_name !== null) data.company_name = company_name.value;
    //             if (company_url !== null) data.company_url = company_url.value;
    //             if (offer_title !== null) data.offer_title = offer_title.value;
    //             if (offer_description !== null) data.offer_description = offer_description.value;
    //             if (offer_requirements !== null) data.offer_requirements = offer_requirements.value;
    //             if (offer_conditions !== null) data.offer_conditions = offer_conditions.value;
    //             if (offer_location !== null) data.offer_location = offer_location.value;
    //             if (offer_application_link !== null) data.offer_application_link = offer_application_link.value;
    //             if (offer_contact_email !== null) data.offer_contact_email = offer_contact_email.value;
    //
    //             if (company_file !== null && company_file.value !== '') {
    //                 let f = element.querySelector('[name="company_file"]').files[0];
    //                 f.convertToBase64(base64 => {
    //                     data.company_file = encodeURIComponent(base64);
    //                     data.company_file_name = f.name;
    //                     return data;
    //                 });
    //             }
    //         },
    //         beforeSubmit: (element, hashed) => {
    //             let loader = element.querySelector('.loader__background');
    //             loader.style.display = 'flex';
    //             loader.querySelector('.loader').style.display = 'block';
    //         },
    //         success: (element, response) => {
    //             if (response.status === 'success') {
    //                 let loader = element.querySelector('.loader__background');
    //                 let successMessage = loader.querySelector('.loader__success');
    //                 successMessage.innerHTML = 'Dziękujemy, Twoje zgłoszenie zostało przesłane.';
    //                 loader.querySelector('.loader').style.display = 'none';
    //                 element.querySelector('[name="company_name"]').value = '';
    //                 element.querySelector('[name="company_url"]').value = '';
    //                 element.querySelector('[name="company_file"]').value = '';
    //                 element.querySelector('[name="title"]').value = '';
    //                 element.querySelector('[name="description"]').value = '';
    //                 element.querySelector('[name="requirements"]').value = '';
    //                 element.querySelector('[name="conditions"]').value = '';
    //                 element.querySelector('[name="location"]').value = '';
    //                 element.querySelector('[name="application_url"]').value = '';
    //                 element.querySelector('[name="contact_email"]').value = '';
    //
    //                 successMessage.style.display = 'block';
    //                 setTimeout(() => {
    //                     loader.style.display = 'none';
    //                     successMessage.style.display = 'none';
    //                     successMessage.innerHTML = '';
    //                 }, 8000);
    //             }
    //         },
    //         error: (element, response) => {
    //             console.log(response);
    //             let loader = element.querySelector('.loader__background');
    //             let errorMessage = loader.querySelector('.loader__error');
    //             errorMessage.innerHTML = 'Przepraszamy wystąpił problem. Odśwież stronę i spróbuj ponownie.';
    //             loader.querySelector('.loader').style.display = 'none';
    //             errorMessage.style.display = 'block';
    //             setTimeout(() => {
    //                 loader.style.display = 'none';
    //                 errorMessage.style.display = 'none';
    //                 errorMessage.innerHTML = '';
    //             }, 8000);
    //         }
    //     });
    // }
});